.dt-curso-usuarios {
    margin-top: 30px;

    .p-datatable-thead {
        tr {
            th {
                // Gris claro
                background: #f0f0f0 !important;
                color: #4d4d4d !important;
                padding: 10px 5px !important;
                border-radius: 30px;
                font-weight: 600 !important;
                font-size: 16px !important;
            }
        }
    }

    .p-datatable-tbody {
        tr {
            td {
                color: black !important;
                font-weight: 700 !important;
                font-size: 14px !important;

                .botones-header {
                    display: flex;
                    justify-content: flex-start;

                    gap: 5px;

                    .contenedor {
                        font-size: 12px;

                        span {
                            color: white !important;
                            background: #6eb029 !important;
                            padding: 2px 3px;
                            margin-bottom: 2px;
                        }
                    }
                }
            }
        }
    }
}

.dt-cursos {
    margin-top: 30px;

    .p-datatable-thead {
        tr {
            th {
                // Gris claro
                background: #f0f0f0 !important;
                color: #4d4d4d !important;
                padding: 10px 5px !important;
                border-radius: 30px;
                font-weight: 600 !important;
                font-size: 20px !important;

            }
        }
    }

    .p-datatable-tbody {
        tr {
            td {
                color: black !important;
                font-weight: 700 !important;
                font-size: 18px !important;

            }
        }
    }
}

.input-table {
    input {
        background: #f0f0f0;
        color: black !important;
        border: 0px;
        border-radius: 10px;
        font-size: 17px;
        box-shadow: none !important;
    }

    i {
        color: #4d4d4d;
    }
}

.p-datatable {
    .p-datatable-thead {
        tr {
            th {
                background: #f0f0f0 !important;
                color: #4d4d4d !important;
                padding: 10px 5px !important;
                border-radius: 30px;
                font-weight: 600 !important;
                font-size: 20px;
            }
        }
    }

    .p-datatable-tbody {

        tr {
            td {
                color: black !important;
                font-weight: 700 !important;
                font-size: 18px;
            }
        }
    }
}