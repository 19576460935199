// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "./styles//buttons.scss";
@use "./styles/tables.scss";
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$capacitacion-primary: mat-palette($mat-indigo);
$capacitacion-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$capacitacion-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$capacitacion-theme: mat-light-theme((color: (primary: $capacitacion-primary,
        accent: $capacitacion-accent,
        warn: $capacitacion-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($capacitacion-theme);

/* You can add global styles to this file, and also import other style files */


.no-valid {
  border: 2px solid red !important;
  // background: white !important;
}

.valid {
  border: 2px solid greenyellow;
}




.title {
  text-align: center;
  color: black;

}

.rowBody {
  td {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 100%;

    }
  }
}


.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 100%;
  word-break: break-word;
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 100%;
  word-break: break-word;
}

.four-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height: 100%;
  word-break: break-word;
}

.beneo-button {
  color: #ffffff;
  background: #21cc2d !important;
  border: 1px solid #21cc2d !important;
}


body {
  // background:url('./assets/img/fondo.png') !important;
  background: white;
  font-family: 'Assistant', sans-serif !important;
  color: black !important;

  .p-dropdown {
    font-family: 'Assistant', sans-serif !important;

  }

  .p-component {
    font-family: 'Assistant', sans-serif !important;
    $main-green: #b6ceb7;
    color: black;



  }

  .p-dataview {
    color: black !important;

    .p-dataview-header {
      background: transparent;
    }

    .p-dataview-emptymessage {
      background: transparent;
    }

    .p-dataview-content {
      background: transparent;
      color: black !important;
    }
  }

  .p-toolbar {
    background: transparent;
    border: none;
  }

  .p-dialog {
    width: 70%;

    .p-dialog-content {
      background: white;
    }
  }
}




// Icons
.exportar_informe {
  content: url('./assets/icons/exportar_informe.svg');
  width: 30px;
}

.agregar_usuario {
  content: url('./assets/icons/agregar_usuarios.svg');
  width: 30px;
}

.exportar_resultados {
  content: url('./assets/icons/exportar_resultados.svg');
  width: 30px;
}

.exportar_gris {
  content: url('./assets/icons/exportar_gris.svg');
  width: 25px;

}

.verificar {
  content: url('./assets/icons/verificar.svg');
  width: 25px;

}

.examen {
  content: url('./assets/icons/examen.svg');
  width: 25px;

}


// Finalizar iconos

.confirm-dialog {
  @media(min-width:600px) {
    width: 400px !important;
  }

  @media(max-width:600px) {
    width: 300px !important;
  }
}


.p-datatable {
  .p-datatable-header {
    background: transparent !important;
    border: none !important;
    color: black !important;
  }

  .p-datatable-footer {
    background: transparent !important;
    border: none !important;
    color: black !important;
  }

  .p-datatable-wrapper {
    color: black !important;

    .p-datatable-thead {
      tr {

        background: transparent;

        th {
          text-align: center;
          color: black;
          background: transparent;
        }
      }
    }

    .p-datatable-tbody {
      tr {
        background: transparent;

        @media(max-width:40em) {
          border-bottom: 2px solid black;
        }


        td {
          text-align: center;
          color: black;
          background: transparent;
        }
      }
    }

    .p-datatable-tfoot {
      color: black !important;
    }
  }
}

.p-datepicker {
  top: 289px !important;

}