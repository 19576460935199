.p-button-success {
    background: #6eb029 !important;
    border-radius: 10px !important;
    color: white !important;
}

.p-button-gray {
    background: #f0f0f0 !important;
    color: #4d4d4d !important;
    border: 0px;
    box-shadow: none !important;

    i {
        color: #4d4d4d !important;
    }
}